import { createRoot } from 'react-dom/client';
import $ from 'jquery';
import {useContext, useState, useEffect} from 'react'; 
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { Modal, Spinner, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Context } from '../../components/Context';
import  MapContainer from '../../components/GoogleMap';
import { toastNotify, validateForm, downloadReport, downloadMediaFile } from '../../components/Helper';
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { getRequest, postFileData, deleteRequest }  from '../../components/Services/Api';
import Datatables, { reloadDataTable, reloadUrlDataTable, redrawDataTable } from '../../components/Tabel/Datatables';


function SiteListServerSide(){
    window.document.title = "All Sites | I2R Portal";

    localStorage.removeItem('site-tab');
    let latitude = '';
    let longitude = '';

    const [user] = useContext(Context);
    const [ reload, setReload ] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const {register} = useForm();
    const { register: register2 } = useForm();
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [displayUploadsModal, setDisplayUploadsModal] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayMapModal, setDisplayMapModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [status, setStatus] = useState('');
    const [site, setSite] = useState(null);
    const [siteName, setSiteName] = useState(null);
    const [siteLocation, setSiteLocation] = useState(null);
    const [id, setId] = useState(null);

    const iconMarker = './images/mappin.png';

    const [dt] = useState({
        dt_url: `site/all`,
        dt_name: 'site_list',
        dt_export : true,
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Name"},
            { data:"license_no", name:'license_no', title:"License No", class:"text-nowrap text-truncate"},
            { data:"license_expiry", name:'license_expiry',  title:"Expires on", class:"text-truncate maxw-250"},
            { data:"address", name:'address',  title:"Site Address", class:"text-truncate maxw-250"},
            { data:"created_at", name:'created_at',  title:"Added On", class:"text-nowrap" },
            { data:"id", title:"Action", sortable:false, searchable:false, orderable: false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <div className="text-nowrap text-truncate maxw-250">
                            <OverlayTrigger placement="top" overlay={<Tooltip> {rowData.name} </Tooltip>}>
                                <span>{rowData.name}</span>
                            </OverlayTrigger>
                        </div>                             
                    </>)
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        { ( user && user.allowed && user.allowed.includes('site-license-update')) ? 
                            <div className="center d-flex">
                                <div className="me-auto">{rowData.license_no}</div>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip> Upload license details </Tooltip>}>
                                    <span className="badge bg-blue ms-3" title='Update' onClick={() => handleUploadLicense(rowData.id)}> 
                                        <svg className="icon tscale-1p2" role="img"><use href="#icon_magic" /></svg>
                                    </span>   
                                </OverlayTrigger>
                               
                            </div> 
                            :
                            <div className="me-auto">{rowData.license_no}</div> 
                        } 
                        <div className='ms-1'>
                            { rowData.license_file && 
                                <OverlayTrigger placement="bottom" overlay={<Tooltip> Download license details </Tooltip>}>
                                    <button type='button' className="badge bg-warning border-0" onClick={(e) => downloadMediaFile(rowData.license_file, 'licenses')}>
                                        <svg className="icon tscale-1p5" role="img">
                                            <use href="#icon_download" />
                                        </svg>
                                    </button>
                                </OverlayTrigger>
                            }
                        </div>                         
                    </>)
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <div className="text-nowrap">
                            <OverlayTrigger placement="top" overlay={<Tooltip> {rowData.license_expiry_status === 1 ? `License expired on ${rowData.license_expiry}` : `License valid till ${rowData.license_expiry}`} </Tooltip>}>
                                <span className={rowData.license_expiry_status === 1 ? 'text-danger' : 'text-success'}>
                                    <svg className="icon tscale-1p1 text-info" role="img">
                                        <use href="#icon_clock" />
                                    </svg> 
                                    <span className='mx-2'>{rowData.license_expiry} </span>
                                </span>
                            </OverlayTrigger>
                        </div>                             
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    if(!rowData.latitude && !rowData.longitude){
                        latitude = rowData.latlong.lat;
                        longitude = rowData.latlong.lng;
                    }else{
                        latitude = rowData.latitude;
                        longitude = rowData.longitude;
                    }
                    createRoot(td).render(<>
                        <div className="text-nowrap text-truncate maxw-250">
                            { (latitude && longitude) && 
                                <span className='me-2'>
                                    <OverlayTrigger placement="top" overlay={<Tooltip> View on Google Map </Tooltip>}>
                                    <button type='button' className='pointer btn btn-link' onClick={() => handleGoogleMap(rowData.name, rowData.address, latitude, longitude)}> 
                                        <img src={iconMarker} width={25} alt="Map View" />
                                    </button>
                                    </OverlayTrigger>
                                </span>
                            }
                            <OverlayTrigger placement="top" overlay={<Tooltip> {rowData.address} </Tooltip>}>
                                <span>{rowData.address}</span>
                            </OverlayTrigger>
                        </div>                             
                    </>)
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <div className="text-nowrap">

                            <OverlayTrigger placement="top" overlay={<Tooltip> View more about site </Tooltip>}>
                                <a href={`/admin/site/view/${rowData.id}`} className="btn btn-sm btn-success me-1">View & Manage</a>
                            </OverlayTrigger>

                            { ( user && user.allowed && user.allowed.includes('site-license-update')) &&
                                <OverlayTrigger placement="top" overlay={<Tooltip> Update Status </Tooltip>}>
                                    <Button variant="primary" className="btn btn-sm me-1" onClick={() => handleUpdate(rowData.id, rowData.license_status)}>
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_magic" /></svg>
                                    </Button>
                                </OverlayTrigger>
                            }
                            { (user && user.allowed && user.allowed.includes('report-site-download')) &&
                                <OverlayTrigger placement="top" overlay={<Tooltip> Download site report </Tooltip>}>
                                    <Button variant="warning" className="btn btn-warning btn-sm me-1 btn-loading" onClick={(e) => downloadReport(e, rowData.id)}>
                                        <Spinner className="spinner-border spinner-border-sm text-right pe-none" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> 
                                        <svg className="icon tscale-1p3 pe-none" role="img"><use href="#icon_download" /></svg>
                                    </Button>
                                </OverlayTrigger>
                            }
                            { (user && user.allowed && user.allowed.includes('site-edit')) &&
                                <OverlayTrigger placement="top" overlay={<Tooltip> Edit This Site </Tooltip>}>
                                    <a href={`/admin/site/edit/${rowData.id}`} className="btn btn-sm btn-primary me-1">
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_edit" /></svg>
                                    </a>
                                </OverlayTrigger>
                            }
                            { (user && user.allowed && user.allowed.includes('site-delete')) &&
                                <OverlayTrigger placement="top" overlay={<Tooltip> Delete This Site </Tooltip>}>
                                    <Button variant="danger" className="btn btn-sm" onClick={() => handleDelete(rowData.id)}>
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_trash" /></svg>
                                    </Button>
                                </OverlayTrigger>
                            }
                        </div>                             
                    </>)
                },
            },
        ]
    });

    useEffect(() => {
        reloadUrlDataTable(dt, `site/all/${selectedOption}`);

        if(!$('.dt-custom-filter select').hasClass('expirylicense')){
            $('.dt-custom-filter').append(`
            <select class="expirylicense form-select form-select-sm w-auto d-inline-block mt-1 mt-sm-0 me-sm-3 float-end" onchange="SelectColumnFilter(this.value)" 
            value="all">
                <option value="all">All License</option>
                <option value="0">Upcoming Renewals</option>
                <option value="1">Expired License</option>
            </select>`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    window.SelectColumnFilter = (option) => {
        setSelectedOption(option);
        reload === true ? setReload(false) : setReload(true);
    }

    // Show Google Map Model
    const handleGoogleMap = (siteName = '', Address = '', latitude = '', longitude = '') => {
        setSiteName(siteName);
        setSiteLocation({
            name: siteName ? siteName : 'I2R Portal',
            address: Address ? Address : '',
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
        });
        setDisplayMapModal(true);
    }

    async function handleDelete(id){
        setId(id);
        setDisplayConfirmationModal(true);
    }
    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`site/delete/${id}`,(responce)=> {
            setDisplayConfirmationModal(false);
            if(responce.success){
                redrawDataTable(dt);
            }else{
                toastNotify('danger', responce.message);
            }
        }); 
    }
    
    async function SelectStatusOption(option){
        setStatus(option);
    }
    // Update licence status
    const handleUpdate = (id, license_status ='') => {
        setId(id);
        setStatus('');
        getRequest(`site/get/${id}`, (response)=> {
            if(response.success){
                const content = response.data;
                setSite(content);
                setDisplayModal(true);
                setStatus(license_status ? license_status : '');
            }
        });   
    }

    //Uploads licence files
    const handleUploadLicense = (id) => {
        setId(id);
        getRequest(`site/get/${id}`, (response)=> {
            if(response.success){
                const content = response.data;
                setSite(content);
                setDisplayUploadsModal(true);
            }
        });   
    }

    const onLicenceSubmit = (e) => {
        if (validateForm(e)) {
            setTinyloader(true);
            let formData = new FormData(document.getElementById('siteLincenceForm'));
            updateSiteLicence(formData);
        }
    }

    const onStatusSubmit = (e) => {
        if (validateForm(e)) {
            let formData = new FormData(document.getElementById('siteStatusForm'));
            if(formData.get('license_status') === 'Renewed' && !formData.get('license_file')){
                toastNotify('danger', 'Upload license required.');
            }else{
                setTinyloader(true);
                updateSiteLicence(formData);
            } 
        }
    }

    const updateSiteLicence = (data) => {
        try {
            postFileData(`site/update_licence/${id}`, data, 'post', (responce)=> {
                setTinyloader(false);
                if(responce.success){
                    toastNotify('success', responce.message);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
                hideConfirmationModal();
                reloadDataTable(dt);
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setTinyloader(false);
        }
    }

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
        setDisplayModal(false);
        setDisplayMapModal(false);
        setDisplayUploadsModal(false);
        setStatus('');
    };

    return(
        <>
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-md-10">
                        <h4 className="page-heading">All Sites</h4>
                    </div>
                    <div className="col-auto ms-auto col-md-2 mb-2 mt-2">
                        { (user && user.allowed && user.allowed.includes('site-create')) &&
                            <OverlayTrigger placement="left" overlay={<Tooltip> Click here to Add New Site </Tooltip>}>
                                <Link to="../site/new" role="button" className="btn btn-primary px-3 text-nowrap float-end" >
                                    <svg className="icon tscale-1p3 me-1 pe-none" role="img"><use href="#icon_plus" /></svg> Add New Site
                                </Link>
                            </OverlayTrigger>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                                <Datatables dt_name="site_list" dataPageLength="15"/>
                                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            { displayUploadsModal && <Modal show={displayUploadsModal} onHide={hideConfirmationModal} className="modal" id="uploadsLicence">
                <div className="modal-header">
                    <h5 className="modal-title" id="uploadsLicenceLabel">Upload License - { site ? site.name : 'Box' }</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form className='needs-validation' id='siteLincenceForm' name='siteLincenceForm' noValidate>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="license_file" className="col-md-11 col-form-label">Upload License<strong className="text-danger"> *</strong></label>
                            <div className="col-md-11">
                                <input className="form-control" id='license_file' type="file" name="license_file" {...register('license_file')} required />
                            </div>
                        </div> 
                        <div className="row mb-3 justify-content-center">
                            <div className="col-md-11">
                                <div className="text-end">
                                <button type="button" onClick={(e) => onLicenceSubmit(e) }  className="btn btn-primary btn-lg w-100 rounded-0 mb-3" disabled={tinyloader}>
                                    { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit 
                                </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
              </Modal>
            }
            { displayModal && <Modal show={displayModal} onHide={hideConfirmationModal} className="modal" id="updateLicence">
                <div className="modal-header">
                    <h5 className="modal-title" id="updateLicenceLabel">Upload License - { site ? site.name : 'Box' }</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form className='needs-validation' id='siteStatusForm' name='siteStatusForm' noValidate>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="license_status" className="col-md-11 col-form-label">Status<strong className="text-danger"> *</strong></label>
                            <div className="col-md-11">
                                <select name="license_status" value={status} {...register2('license_status')} id="license_status" onChange={e => SelectStatusOption(e.target.value)} className={`form-select rounded-0` } required>
                                    <option value="">Please select</option>
                                    <option value="1">Renewed</option>
                                    <option value="2">Decommissioned</option>
                                    <option value="3">No Longer Managed</option>
                                </select> 
                            </div>
                        </div>
                        { (status && status === 'Renewed') && 
                            <div className="row mb-3 justify-content-center">
                                <label htmlFor="license" className="col-md-11 col-form-label">Upload License<strong className="text-danger"> *</strong></label>
                                <div className="col-md-11">
                                    <input className="form-control" id='license' type="file" name="license_file" {...register2('license_file')} required />
                                </div>
                            </div> 
                        }  
                        <div className="row mb-3 justify-content-center">
                            <div className="col-md-11">
                                <div className="text-end">
                                <button type="button" onClick={(e) => onStatusSubmit(e) } className="btn btn-primary btn-lg w-100 rounded-0 mb-3" disabled={tinyloader}>
                                    { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit 
                                </button>
                                </div>
                            </div>
                        </div>
                    </form>
                 </div>
              </Modal>
            }
            { (displayMapModal && user && user.api_key) && <Modal size={'lg'} show={displayMapModal} onHide={hideConfirmationModal} className="modal" id="displayGoogleMapModal">
                <div className="modal-header">
                    <h5 className="modal-title" id="uploadsLicenceLabel">{ siteName ? siteName : 'I2R Portal' }</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <div className="map-container-model"> 
                        <MapContainer height="400" apiKey={user.api_key} location={siteLocation} zoomLevel={16} />
                    </div>
                </div>
              </Modal>
            }
        </>
    )
}

export default SiteListServerSide